import React from 'react'
import ResponsiveImageModel from './models'
import { ImageResizeFormat, getResizedImageSrc } from '../../lib/helpers'
import Handlebars from 'handlebars'
import { LinkWrapper } from '../HeroBanner'
import { useAppSelector } from '../../redux'

/**
 * Usage:
 * {{url}}
 */
Handlebars.registerHelper('url', () => window.location.href)

/**
 * Usage:
 * {{title}}
 */
Handlebars.registerHelper('title', () => window.document.title)

interface ResponsiveImageProps {
  data?: ResponsiveImageModel
  containerProps?: string[]
}

export default function ResponsiveImage({ data, containerProps }: ResponsiveImageProps) {
  let href = data?.href || ''

  if (data?.href) {
    try {
      const template = Handlebars.compile(data?.href, { noEscape: true })
      href = template({})
    } catch (e) {
      href = data?.href || ''
      console.log(e)
    }
  }

  return (
    <div {...containerProps}>
      <LinkWrapper href={href} isNewTab={data?.isNewTab}>
        <Picture data={data} />
      </LinkWrapper>
    </div>
  )
}

interface ResizedImageProps {
  imagePath: string
  breakpoint: number
}

const ResizedImage = ({ imagePath, breakpoint }: ResizedImageProps) => {
  return (
    <source
      srcSet={getResizedImageSrc(imagePath, ImageResizeFormat.fitIn, breakpoint, breakpoint)}
      media={`(max-width: ${breakpoint}px)`}></source>
  )
}

interface PictureProps {
  data?: ResponsiveImageModel
}
const Picture = ({ data }: PictureProps) => {
  const isSnapshotRender = useAppSelector((state) => state.app.isSnapshotRender) 
  const isResizingImage = (imagePath: string) => !imagePath.endsWith('.gif')
  const smallBreakpoint = data?.smallBreakpoint || 320
  const mediumBreakpoint = data?.mediumBreakpoint || 640
  const largeBreakpoint = data?.largeBreakpoint || 1280
  const maxMobileBreakpoint = 768

  return (
    <picture>
      {!isSnapshotRender && data?.imagePath && isResizingImage(data.imagePath) && (
        <>
          <ResizedImage imagePath={data.imagePath} breakpoint={smallBreakpoint} />
          <ResizedImage imagePath={data.imagePath} breakpoint={mediumBreakpoint} />
          {data?.desktopImageEnabled && data?.desktopImagePath && (
            <ResizedImage imagePath={data.imagePath} breakpoint={maxMobileBreakpoint} />
          )}
          {!(data?.desktopImageEnabled && data?.desktopImagePath) && (
            <ResizedImage imagePath={data.imagePath} breakpoint={largeBreakpoint} />
          )}
        </>
      )}
      {!isSnapshotRender && data?.imagePath &&
        data?.desktopImageEnabled &&
        data?.desktopImagePath &&
        !isResizingImage(data.imagePath) && (
          <source srcSet={data.imagePath} media={`(max-width: ${maxMobileBreakpoint}px)`}></source>
        )}
      {!isSnapshotRender && data?.desktopImageEnabled &&
        data?.desktopImagePath &&
        isResizingImage(data.desktopImagePath) && (
          <>
            {!data?.imagePath && (
              <>
                <ResizedImage imagePath={data.desktopImagePath} breakpoint={smallBreakpoint} />
                <ResizedImage imagePath={data.desktopImagePath} breakpoint={mediumBreakpoint} />
              </>
            )}
            <ResizedImage imagePath={data.desktopImagePath} breakpoint={largeBreakpoint} />
          </>
        )}
      <img
        loading={data?.lazyLoadEnabled ? 'lazy' : undefined}
        src={
          data?.desktopImageEnabled && data?.desktopImagePath
            ? data?.desktopImagePath
            : data?.imagePath
        }
        alt={data?.alt}
        style={{ width: '100%', height: 'auto' }}
      />
    </picture>
  )
}
