import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import Snippet from '../../elements/Snippet'
import SnippetModel from '../../elements/Snippet/models'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getProduct } from '../../redux/slices/product'

export function SnippetPortal({ id, data }: { id: string; data: SnippetModel }) {
  const [loading, setLoading] = useState(true)
  const pageType = useAppSelector((state) => state.app.config.context?.pageType)
  const collection = useAppSelector((state) => state.collectionElement.currentCollection)
  const productId = useAppSelector((state) => state.app.config.context?.productId)
  const products = useAppSelector((state) => state.product.products)
  const dispatch = useAppDispatch()

  const parentData = useMemo(() => {
    switch (pageType) {
      case 'product':
        if (productId) {
          if (products[productId]) {
            setLoading(false)
            return products[productId]
          }
          dispatch(getProduct({ id: productId?.toString(), idType: 'sourceId' }))
        }
      case 'collection':
        setLoading(false)
        return collection
      default:
        setLoading(false)
        return undefined
    }
  }, [pageType, collection, productId, products])

  const placements = document.querySelectorAll<HTMLElement>(`.${id}`)

  if (placements) {
    placements.forEach((placement) => {
      placement.style.display = 'inherit'
    })

    return (
      <>
        {Array.from(placements).map((placement) =>
          ReactDOM.createPortal(
            <Snippet
              data={data}
              parentData={parentData}
              dataContext={placement.parentElement?.dataset}
            />,
            placement
          )
        )}
      </>
    )
  } else {
    return <></>
  }
}
