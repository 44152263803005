import React, { ReactNode, useCallback } from 'react'
import HeroBannerModel from './models'
import { Elements, Element } from '../index'
import styled from '@emotion/styled'
import { useAppSelector } from '../../redux'

interface Props {
  height: number
  data?: HeroBannerModel
  containerProps?: any
}

const HeroBannerWrapper = styled.div<Props>`
  position: relative;
  overflow: hidden;

  width: ${(props) => props.data?.style?.widthType === 'auto' ? 'auto' : props.data?.style?.widthValue };
  height: ${(props) => props.data?.style?.heightType === 'auto' ? 'auto' : props.data?.style?.heightValue };
  top: ${(props) => props.data?.style?.topType === 'auto' ? 'auto' : props.data?.style?.topValue };
  padding-top: ${(props) => props.data?.style?.paddingTop};
  padding-right: ${(props) => props.data?.style?.paddingRight};
  padding-bottom: ${(props) => props.data?.style?.paddingBottom};
  padding-left: ${(props) => props.data?.style?.paddingLeft};
  margin-top: ${(props) => props.data?.style?.marginTop};
  margin-right: ${(props) => props.data?.style?.marginRight};
  margin-bottom: ${(props) => props.data?.style?.marginBottom};
  margin-left: ${(props) => props.data?.style?.marginLeft};
  background-color: ${(props) => props.data?.style?.backgroundColor};
  border-width: ${(props) => props.data?.style?.borderThickness !== '0px' ? props.data?.style?.borderThickness : `${props.data?.style?.borderTop} ${props.data?.style?.borderRight} ${props.data?.style?.borderBottom} ${props.data?.style?.borderLeft}`};
  border-color: ${(props) => props.data?.style?.borderColor};
  border-radius: ${(props) => props.data?.style?.borderRadius};

  display: ${(props) => {
    if (props.data?.desktopStyleEnabled) {
      if (props.data?.desktopStyle.visibility === 'visible') {
        return 'block'
      }
      return 'none'
    } else {
      if (props.data?.style.visibility === 'visible') {
        return 'block'
      }
      return 'none'
    }
  }};

  @media (max-width: 768px) {
    display: ${(props) => props.data?.style.visibility === 'visible' ? 'block' : 'none'};
  }

  .banner-content {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;

    h1 {
      font-size: 2.75vw;
    }

    h2 {
      font-size: 2.25vw;
    }

    h3 {
      font-size: 1.75vw;
    }

    h4 {
      font-size: 1.25vw;
    }

    p {
      font-size: 1.0vw;
    }
  }

  .banner-background {
    display: block;
    overflow: visible;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`

export default function HeroBanner({ data, containerProps }: Props) {
  // For HeroBanner, render the link as enclosing the entire banner
  const href = data?.backgroundImage?.href ?? ''
  const isNewTab = data?.backgroundImage?.isNewTab
  const backgroundImageData = {...data?.backgroundImage}
  delete backgroundImageData?.href

  return (
    <HeroBannerWrapper data={data} {...containerProps}>
      <LinkWrapper href={href} isNewTab={isNewTab}>
        <div className='banner-content' style={{ position: 'absolute' }}>
          <Elements name='Banner Content' data={data} field='elements' />
        </div>
        <div className='banner-background' style={{ zIndex: 9999 }}>
          <Element data={backgroundImageData} />
        </div>
      </LinkWrapper>
   </HeroBannerWrapper>
  )
}

interface LinkWrapperProps {
  href: string
  isNewTab?: boolean
  children: ReactNode
}

export function LinkWrapper({ href, isNewTab = false, children }:LinkWrapperProps) {
  const isAdmin = useAppSelector(state => state.app.isAdmin)

  const onClickHandler = useCallback( (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  if (isAdmin) {
      alert(`Links are blocked while editing: \n${href}`)
    } else {
      isNewTab ? window.open (href, '_blank') : window.location.href = href
    }
  }, [href])

  if(!href || href?.length === 0) return <>{children}</>

  return <a href={href} onClick={onClickHandler}>{children}</a>
}