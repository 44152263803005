import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import app from './slices/app'
import wishlist from './slices/wishlist'
import collection from './slices/collection'
import collectionElement from './slices/collection-element'
import product from './slices/product'
import cart from './slices/cart'

function lastAction(state = null, action: Action) {
  return action
}

const reducer = combineReducers({
  app,
  wishlist,
  collection,
  collectionElement,
  product,
  lastAction,
  cart
})

const store = configureStore({
  reducer
})

export type RootState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
