import React, { useContext, useMemo } from 'react'
import ButtonModel from './models'
import styled from '@emotion/styled'
import ButtonStyleModel from '../ButtonStyle/models'
import { useAppDispatch, useAppSelector } from '../../redux'
import { resetFilters } from '../../redux/slices/collection-element'
import { ModalContext } from '../Modal/index'

interface ContentProps {
  data?: ButtonModel
  containerProps?: string[]
  dataContext?: any
}

interface ButtonStyleWrapperProps {
  model?: ButtonModel
  buttonStyle?: ButtonStyleModel
}

const ButtonStyleWrapper = styled.button<ButtonStyleWrapperProps>`
  display: 'inline-block';
  padding-top: ${(props) => props.buttonStyle?.paddingTop};
  padding-right: ${(props) => props.buttonStyle?.paddingRight};
  padding-bottom: ${(props) => props.buttonStyle?.paddingBottom};
  padding-left: ${(props) => props.buttonStyle?.paddingLeft};
  margin-top: ${(props) => props.buttonStyle?.marginTop};
  margin-right: ${(props) => props.buttonStyle?.marginRight};
  margin-bottom: ${(props) => props.buttonStyle?.marginBottom};
  margin-left: ${(props) => props.buttonStyle?.marginLeft};
  font-size: ${(props) => props.buttonStyle?.fontSize};
  background-color: ${(props) => props.buttonStyle?.backgroundColor};
  border-width: ${(props) => props.buttonStyle?.borderThickness};
  border-color: ${(props) => props.buttonStyle?.borderColor};
  border-radius: ${(props) => props.buttonStyle?.borderRadius};
  color: ${(props) => props.buttonStyle?.textColor};
  width: ${(props) => props.buttonStyle?.widthType === 'auto' ? 'auto' : props.buttonStyle?.widthValue };
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: .25;
  }

  &:not([disabled]):hover {
    background-color: ${(props) => props.buttonStyle?.backgroundColorHover};
    border-color: ${(props) => props.buttonStyle?.borderColorHover};
    color: ${(props) => props.buttonStyle?.textColorHover};
  }
`

export default function Button({ data, containerProps, dataContext }: ContentProps) {
  const isAdmin = useAppSelector(state => state.app.isAdmin)
  const dispatch = useAppDispatch()
  const filters = useAppSelector(state => state.collectionElement.params)
  const buttonStyle = data?.buttonStyle === 'custom' ? data?.customStyle : data?._buttonStyle
  const modalContext = useContext(ModalContext)

  const disabled = useMemo(() => {
    switch (data?.action) {
      case 'collections':
        switch (data?.collectionActions) {
          case 'reset-collection-filters':
            return Object.keys(filters).length === 0
        }      
      default:
        return false
    }    
  }, [filters, data])

  const onClickButton = (e:any) => {
    switch (data?.action) {
      case 'link':
        if (data?.link) {
          e.preventDefault()
          e.stopPropagation()      

          if (isAdmin) {
            alert(`Links are blocked while editing: \n${data?.link}`)
          } else {
            window.location.href = data?.link
          }
        }
        break;
      case 'collections':
        switch(data?.collectionActions) {
          case 'reset-collection-filters':
            dispatch(resetFilters())
          break;
        }
        break;
      case 'modal':
        switch(data?.modalActions) {
          case 'close-modal':
            modalContext?.close()
          break;
        }
        break;
      default:
        break;
    }
  }
  
  return (
    <ButtonStyleWrapper
      model={data}
      buttonStyle={buttonStyle}
      disabled={disabled}
      onClick={onClickButton}
      onKeyUp={(e)=> e.code === "Enter" ? onClickButton(e): null}
      {...containerProps}>
      {data?.title}
    </ButtonStyleWrapper>
  )
}
