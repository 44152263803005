import React from 'react'
import ButtonStyleModel from './models'
import styled from '@emotion/styled'

interface ButtonStyleProps {
  data?: ButtonStyleModel
  containerProps?: string[]
}

export const ButtonStyleWrapper = styled.button<Pick<ButtonStyleProps, 'data'>>`
  display: 'inline-block';
  padding-top: ${props => props.data?.paddingTop};
  padding-right: ${props => props.data?.paddingRight};
  padding-bottom: ${props => props.data?.paddingBottom};
  padding-left: ${props => props.data?.paddingLeft};
  margin-top: ${props => props.data?.marginTop};
  margin-right: ${props => props.data?.marginRight};
  margin-bottom: ${props => props.data?.marginBottom};
  margin-left: ${props => props.data?.marginLeft};
  background-color: ${props => props.data?.backgroundColor};
  border-width: ${props => props.data?.borderThickness};
  border-color: ${props => props.data?.borderColor};
  border-radius: ${props => props.data?.borderRadius};
  color: ${props => props.data?.textColor};
  width: ${props => props.data?.widthType === 'auto' ? 'auto' : props.data?.widthValue};
  cursor: pointer;
  font-size: ${props => props.data?.fontSize};
  
  &:hover {
    background-color: ${props => props.data?.backgroundColorHover};
    border-color: ${props => props.data?.borderColorHover};
    color: ${props => props.data?.textColorHover};
  }
`

export default function ButtonStyle({ data, containerProps }: ButtonStyleProps) {
  return (
    <div {...containerProps}>
      <ButtonStyleWrapper data={data}>
        {data?.name}
      </ButtonStyleWrapper>
    </div>
  )
}
