import React from 'react'
import styled from '@emotion/styled'
import { Elements } from '../index'
import SnippetModel from './models'
import { LayoutContext } from '../Layout'

interface Props {
  data?: SnippetModel
  parentData?: any
  containerProps?: string[]
  dataContext?: any
}

const Wrapper = styled.div<Props>``

export default function Snippet({ data, parentData, containerProps, dataContext }: Props) {
  
  return (
    <Wrapper {...containerProps} data={data}>
      <LayoutContext.Provider value={parentData || data}>
        <Elements name='Snippet' data={data} field='elements' dataContext={dataContext} />
      </LayoutContext.Provider>
    </Wrapper>
  )
}
