import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { AppConfig } from './types'
import store from './redux'
import { Provider } from 'react-redux'
import AppBasicLoader from './AppBasicLoader'
import { storeSettings, connectCustomer } from './redux/slices/app'

declare global {
  interface Window {
    ElementComponents: any
  }
}

/**
 * This is the stripped down version of widget2 which includes the basic elements
 * to enable headless cart
 *
 *
 * MOST IC widget features will not work
 *
 */

const DEFAULT_SERVER = 'https://app.nogin.com'
const ElementComponents = import.meta.glob(
  `./elements/(Modal|AddToCartButton|Button|ButtonStyle|CartFreeShippingProgress|CartLineItems|SideCart|CheckoutButton|ContainerStyle|Section|ResponsiveImage|Html|Code)/!(*.test.tsx|_*|*.ts)`,
  { eager: true }
)
window.ElementComponents = ElementComponents

const init = (config: AppConfig) => {
  // setup default axios configuration
  axios.defaults.baseURL = `${config.server || DEFAULT_SERVER}/api/v1`
  axios.defaults.params = {}
  axios.defaults.params['publicToken'] = config.publicToken

  store.dispatch(storeSettings({ config }))
  store.dispatch(connectCustomer({ config }))

  ReactDOM.render(
    <Provider store={store}>
      <AppBasicLoader />
    </Provider>,
    document.getElementById('nogin-toolbox') as HTMLElement
  )
}

export { init }
