import React, { useMemo } from 'react'
import CollectionLayout from './CollectionLayout'
import LayoutModel from './models'
import styled from '@emotion/styled'
import DefaultLayout from './DefaultLayout'

interface ContentProps {
  data?: LayoutModel | undefined
  parentData?: any
  containerProps?: string[]
  productIds?: string[]
  children?: React.ReactElement
}

const Container = styled.div<{ data?: LayoutModel }>`
  @media (min-width: 1280px) {
    &.contained {
      max-width: 1280px;
      margin: 0 auto;
    }
  }
`

export const LayoutContext = React.createContext<any>(null)

export default function Layout({ data, parentData, containerProps, productIds, children }: ContentProps) {
  const LayoutComponent = useMemo(() => {
    switch (data?.contentType) {
      case '#schema:Collection':
        return CollectionLayout
      default:
        return DefaultLayout
    }
  }, [data])

  return (
    <Container data={data} className={data?.layoutStyle}>
      <div {...containerProps}>
        <LayoutContext.Provider value={parentData}>
          {LayoutComponent && <LayoutComponent data={data} containerProps={containerProps} productIds={productIds}/>}
          {children}
        </LayoutContext.Provider>
      </div>
    </Container>
  )
}
