import React from 'react'
import { useAppSelector } from './redux'
import PlacementManager from './components/PlacementManager/PlacementManager'
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react'
import cartFragment from './lib/headless/cartFragment'

function HeadlessAppLoader() {
  const appReady = useAppSelector((state) => state.app.ready)
  const storefront = useAppSelector((state) => state.app.storefront)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site?.enableHeadlessCommerce)

  if (!appReady) return <></>
  
  if (enableHeadlessCommerce && storefront?.url && storefront?.accessToken) {
    return (
      <ShopifyProvider
        storeDomain={`https://${storefront.url}`}
        storefrontToken={storefront.accessToken}
        storefrontApiVersion='2023-10'
        countryIsoCode='US'
        languageIsoCode='EN'>
        <CartProvider cartFragment={cartFragment}>
          <PlacementManager />
        </CartProvider>
      </ShopifyProvider>
    )
  }

  return (
    <CartProvider>
      <PlacementManager />
    </CartProvider>
  )
}

export default HeadlessAppLoader
