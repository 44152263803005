import React, { useContext, useEffect, useRef, useState } from 'react'
import HtmlModel from './models'
import styled from '@emotion/styled'
import { LayoutContext } from '../Layout'
import Handlebars from 'handlebars'
import DOMPurify from 'dompurify'
import { format, formatDistance } from 'date-fns'
import { useAppSelector } from '../../redux'
import { useCart } from '@shopify/hydrogen-react'

interface CodeProps {
  data?: HtmlModel
  parentData?: any
  context?: { [key: string]: any }
  containerProps?: string[]
}

const HtmlElementWrapper = styled.div<CodeProps>`
  ul,
  ol {
    list-style: disc;
    padding: 0 2rem;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 0;
  }
`

// TODO: move this all to shared editor

Handlebars.registerHelper('tagPrefix', (prefix: string, tags: string[]) => {
  if (prefix && Array.isArray(tags)) {
    const tag = tags.find((t) => t.startsWith(prefix))
    if (tag) {
      return tag.replace(prefix, '')
    }
  }
})

//returns 'about 1 month ago' or '3 days ago'
Handlebars.registerHelper('timeAgo', (date: string) => {
  // return empty string if date is null
  return date ? 
    formatDistance(new Date(date), new Date(), { addSuffix: true }) : ''
})

//allows user to specify format, or defaults to 'Apr 1, 2022'
Handlebars.registerHelper('dateFormat', (date: string, dateFormat?: string) => {
  // return empty string if date is null
  return date ? 
    format(new Date(date), typeof dateFormat === 'string' ? dateFormat : 'PP') : ''
})

//compare two values in handlebars
Handlebars.registerHelper('compare', function (lvalue, operator, rvalue, options) {
  let operators: { [key: string]: any }
  let result

  if (arguments.length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters")
  }

  if (options === undefined) {
    options = rvalue
    rvalue = operator
    operator = '==='
  }

  operators = {
    '==': function (l: any, r: any) {
      return l == r
    },
    '===': function (l: any, r: any) {
      return l === r
    },
    '!=': function (l: any, r: any) {
      return l != r
    },
    '!==': function (l: any, r: any) {
      return l !== r
    },
    '<': function (l: any, r: any) {
      return l < r
    },
    '>': function (l: any, r: any) {
      return l > r
    },
    '<=': function (l: any, r: any) {
      return l <= r
    },
    '>=': function (l: any, r: any) {
      return l >= r
    },
    typeof: function (l: any, r: any) {
      return typeof l == r
    }
  }

  const op = new DOMParser().parseFromString(operator, "text/html").documentElement.textContent || '=='
  if (!operators[op]) {
    throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator)
  }

  result = operators[op](lvalue, rvalue)

  if (result) {
    //@ts-ignore
    return options.fn(this)
  } else {
    //@ts-ignore
    return options.inverse(this)
  }
})

export default function Code({ data, context, containerProps }: CodeProps) {
  const productCache = useAppSelector((state) => state.product.products)
  const products = useAppSelector((state) => state.collectionElement.products)
  const totalProducts = useAppSelector((state) => state.collectionElement.totalProducts)
  const globalContext = useAppSelector((state) => state.app.config.context)
  const parentContext = useContext(LayoutContext)
  const container = useRef<HTMLDivElement>(null)
  const [compiledHtml, setCompiledHtml] = useState('')
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  const cart = useCart()
  
  useEffect(() => {
    let html = ''
    if (data) {
      try {
        const template = Handlebars.compile(data?.code, { noEscape: true })
  
        let extraContext: { [key: string]: any } = {}

        const pageType = globalContext?.pageType || parentContext?.__type.toLowerCase()
        
        switch(pageType) {
          case 'product':
            if (globalContext?.productId) {
              extraContext['product'] = productCache[globalContext?.productId]
              extraContext['totalProducts'] = totalProducts
            }
          break
          case 'collection':
            extraContext['products'] = products
          break
        }

        if (enableHeadlessCommerce) {
          if (cart && cart.cost && cart.cost.subtotalAmount) {
            const subtotal = +(cart.cost.subtotalAmount.amount || 0)
            
            const formatter = Intl.NumberFormat('default', {
              style: 'currency',
              currency: cart.cost.subtotalAmount.currencyCode,
              maximumFractionDigits: 2
            })
  
            extraContext.cart = {
              subtotal: formatter.format(subtotal),
              totalQuantity: cart.totalQuantity || 0
            }
          }
        }
        
        const templateContext = context
          ? {
              parent: (parentContext || {}),
              ...(context || {}),
              ...extraContext
            }
          : {
              ...(parentContext || {}),
              ...extraContext
            }
  
        html = template(templateContext)
      } catch (e) {
        console.log(e)
      }
    }

    setCompiledHtml(html)
  }, [data?.code, parentContext, products])
  
  useEffect(() => {
    const dom = document.createElement('div')
    dom.innerHTML = compiledHtml
    const scripts = dom.getElementsByTagName('script')

    // remove all script from container
    const containerScripts = container.current?.getElementsByTagName('script')
    if (containerScripts) {
      for (const script of containerScripts) {
        container.current?.removeChild(script)
      }
    }

    // add scripts into container
    for(const script of scripts) {
      const newScript = document.createElement('script')
      newScript.type = 'text/javascript'
      newScript.innerHTML = script.innerHTML
      container.current?.appendChild(newScript)
    }
  }, [compiledHtml])

  const cleanHtml = DOMPurify.sanitize(compiledHtml, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })

  return (
    <HtmlElementWrapper
      ref={container}
      dangerouslySetInnerHTML={{ __html: cleanHtml || '' }}
      data={data}
      {...containerProps}></HtmlElementWrapper>
  )
}
