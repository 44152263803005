import React, { useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import { spinalcase } from 'stringcase'
import classNames from 'classnames'
import { useAppSelector } from '../redux'

interface ElementsProp {
  data: any
  field: string
  name?: string
  wrapElement?: (children: JSX.Element, key: number | string) => JSX.Element
  dataContext?: any
}

function Elements({ name, data, field, wrapElement, dataContext }: ElementsProp) {
  const admin = useAppSelector((state) => state.app.isAdmin)
  const title = data?.name || data?.title || ''
  const contentType = data?._contentType?.slug
  const ref = useRef<HTMLDivElement>(null)
  const elements: any[] = data[field] || []
  const [isMouseOver, setMouseOver] = useState(false)

  return (
    <div
      className={classNames({ active: isMouseOver })}
      ref={ref}
      data-title={title}
      data-content-type={contentType}
      data-elementid={`${data.__elementId}_${field}`}
      onMouseOver={(e) => {
        if (admin) {
          e.stopPropagation()
          setMouseOver(true)
          const target = e.nativeEvent.target as HTMLElement
          const closestElement = target.closest('.element') as HTMLElement
          const name = closestElement?.dataset.name
          const selectedElementId = closestElement?.dataset.elementId?.replace(`element_`, '')

          const rect = closestElement.getBoundingClientRect()
          const win = closestElement.ownerDocument.defaultView
          const doc = document.documentElement.getBoundingClientRect()

          const client = {
            id: selectedElementId,
            name: name || '',
            w: rect.width,
            h: rect.height,
            y: rect.top + (win?.scrollY || 0),
            x: rect.left + (win?.scrollX || 0),
            doc: {
              h: doc.height,
              w: doc.width,
              y: win?.scrollY || 0,
              x: win?.scrollX || 0
            },
            fieldNamePath: getFieldNamePath(closestElement)
          }

          window.parent.postMessage(
            {
              action: 'highlight',
              payload: client
            },
            '*'
          )
        }
      }}
      onMouseOut={() => setMouseOver(false)}
    >
      <div className='elements-wrapper'>
        {elements.map((element, i) => {
          const El = (
            <Element
              data={element}
              key={element.__elementId || i}
              onMouseOver={() => {
                setMouseOver(true)
              }}
              fieldParentElementId={data?.__elementId}
              fieldName={field}
              fieldIndex={i}
              dataContext={dataContext}
            />
          )
          if (wrapElement) {
            return wrapElement(El, element.__elementId || i)
          }
          return El
        })}
      </div>
      {/* {admin && (
        <div
          className={classNames('elements-empty-placeholder', {
            active: childElementId === data.__elementId
          })}
          data-field={field}
          data-parentid={data.__elementId}
          onClick={() => {
            window.parent.postMessage(
              { action: 'addElement', payload: { field, parentId: data.__elementId } },
              '*'
            )
          }}
        >
          <div>
            <FiPlus />
          </div>
        </div>
      )} */}
    </div>
  )
}

const Element = ({
  data,
  name,
  slug,
  onMouseOver,
  isCardLayout = false,
  onError,
  fieldParentElementId,
  fieldName,
  fieldIndex,
  dataContext,
  elementId
}: {
  data?: any
  name?: string
  slug?: string
  onMouseOver?: () => void
  isCardLayout?: boolean
  onError?: (e: unknown) => void
  fieldParentElementId?: string
  fieldName?: string
  fieldIndex?: number
  dataContext?: any
  elementId?: string
}) => {
  const [_data, setData] = useState(data)
  const [isActive, setActive] = useState(false)
  const admin = useAppSelector((state) => state.app.isAdmin)

  useEffect(() => {
    setData(data)
  }, [data])

  useEffect(() => {
    async function load() {
      if (!_data && slug && name) {
        const key = spinalcase(name)
        try {
          const response = await axios.get(`/element/data/${key}/slug/${slug}`)

          const elementData = {
            ...response.data.data,
            __id: response.data.parentId,
            __aggs: response.data.aggs,
            __layouts: response.data._layouts,
            __updatedAt: response.data.updatedat
          }
          setData(elementData)
        } catch (e) {
          onError?.(e)
        }
      }
    }

    load()
  }, [_data, name, slug])

  useEffect(() => {
    if (admin) {
      const frameAdminListener = (evt: any) => {
        switch (evt.data?.action) {
          case 'currentElementIdChanged':
            const elementId = evt.data?.payload.currentElementId
            if (elementId === _data?.__elementId) {
              setActive(true)
            } else {
              setActive(false)
            }
            break
        }
      }

      window.addEventListener('message', frameAdminListener)

      return () => {
        window.removeEventListener('message', frameAdminListener)
      }
    }
  }, [admin])

  const Component = useMemo(() => {
    const elementName = name || _data?.__type

    if (elementName) {
      // allows including child components using Collection.ActiveFilterList format
      const [_elementName, componentName] = elementName.split('.')
      const _componentName = componentName || 'index'
      const importName = `./elements/${_elementName}/${_componentName}.tsx`
      let module: any = window.ElementComponents[importName]

      // load generic content type component
      if (!module) {
        const importName = isCardLayout
          ? `./elements/ContentType/GenericCard.tsx`
          : `./elements/ContentType/GenericPage.tsx`
        module = window.ElementComponents[importName]
      }

      return module?.default
    }
  }, [_data, name])

  // containerProps to pass onto child component
  // developers are required to add {...containerProps} to their root element
  // ex: <div {...containerProps}>Your Stuff Goes Here</div>
  const containerProps = useMemo(() => {
    const elementClassName = `element-${spinalcase(name || _data?.__type || '')}`

    return {
      'data-element-id': `element_${_data?.__elementId ? _data?.__elementId : elementId}`,
      className: classNames('element', elementClassName, {
        'element-active': isActive,
        [spinalcase(_data?.name || _data?.title)]: _data?.name || _data?.title
      }),
      'data-name': name || _data?.__type,
      'data-parent-id': _data?.__parentId,
      'data-field-parent-element-id': fieldParentElementId,
      'data-field-name': fieldName,
      'data-field-index': fieldIndex
    }
  }, [_data?.__elementId, isActive])

  if (Component && _data) {
    let componentData = _data
    if (_data?.___embedded) {
      componentData = { ..._data.___embedded }
    }

    return <Component data={componentData} containerProps={containerProps} dataContext={dataContext} />
  } else {
    return <></>
  }
}

/**
 * Gets the field name path for a selected element in the admin preview
 * so it can be edited via the corresponding element field(s) in an element editor form (like ElementSingleEditManager)
 *
 * Uses the following attributes on the element's rendered HTMLElement:
 * data-element-id, data-field-parent-element-id, data-field-name, and data-field-index to build the field name path.
 */
const getFieldNamePath = (currentHTMLElement: HTMLElement, fieldNamePath: any[] = []): any => {
  const { fieldName, fieldIndex, fieldParentElementId } = currentHTMLElement?.dataset ?? {}
  const path = []
  if (fieldName) {
    path.push(fieldName)
  }
  if (fieldIndex) {
    path.push(fieldIndex)
  }
  fieldNamePath = [...path, ...fieldNamePath]
  if (!fieldParentElementId) {
    return fieldNamePath
  }
  const parentHTMLElement = currentHTMLElement.closest(
    `.element[data-element-id=element_${fieldParentElementId}]`
  ) as HTMLElement
  return getFieldNamePath(parentHTMLElement, fieldNamePath)
}

export { Elements, Element }
