import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ModalModel from './models'
import { Elements } from '..'
import classNames from 'classnames'

interface Props {
  data: ModalModel
  containerProps: any
}

const Container = styled.div<{ data: ModalModel }>`
  .launcher {
    display: none;
  }

  @media (max-width: ${(props) =>
      props.data.display === 'mobile' ? '768px' : '10000000000000000px'}) {
    &.modal {
      .modal-panel {
        position: fixed;
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        z-index: 21474836479989;
        justify-content: ${(props) => (props.data.displayMode === 'popup' ? 'center' : 'unset')};

        .modal-content {
          position: relative;
          display: ${(props) => (props.data.displayMode === 'popup' ? 'none' : 'flex')};
          flex-direction: column;
          z-index: 2;
          background-color: white;
          transition: linear;
          transition-duration: 200ms;
          pointer-events: all;

          &.modal-left {
            position: fixed;
            width: 350px;
            left: 0;
            transform: translate(-350px);
            top: 0;
            bottom: 0;
            height: 100%;

            .modal-header {
              padding: 10px;
              text-align: right;
            }
          }

          &.modal-right {
            position: fixed;
            width: 350px;
            right: 0;
            transform: translate(350px);
            top: 0;
            bottom: 0;
            height: 100%;

            .modal-header {
              padding: 10px;
            }
          }

          &.modal-popup {
            justify-self: center;
            align-self: center;
            width: ${(props) => props.data.width};
            height: ${(props) => props.data.height};

            .modal-header {
              position: absolute;
              top: 0;
              padding: 10px;
              z-index: 10;
            }
          }

          .modal-header {
            button {
              border: none;
              background-color: transparent;
              cursor: pointer;
              font-size: 25px;
              color: #888;
            }
          }

          .modal-content-body {
            overflow-y: auto;
            flex-grow: 1;
          }

          .modal-footer {
            flex-shrink: 1;
            width: calc(100% - 20px);
            background-color: white;
            padding: 10px;
          }
        }

        .modal-curtain {
          position: absolute;
          background-color: black;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          z-index: -1;
          display: none;
          pointer-events: all;
        }
      }

      .modal-panel.open {
        .modal-content {
          &.modal-left,
          &.modal-right {
            transform: translate(0px);
          }

          display: flex;
        }

        .modal-curtain {
          display: block;
        }
      }
    }

    .launcher {
      display: block;
      pointer-events: all;
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      background-color: transparent;

      > div {
        pointer-events: none;
      }
    }
  }
`

export const ModalContext = React.createContext<{ close: () => void } | null>(null)

export default function Modal({ data, containerProps }: Props) {
  const [isOpen, setOpen] = useState(false)

  const contextValue = {
    close: () => toggleModalHandler(false)
  }

  const toggleModalHandler = useCallback(
    (toggle: boolean) => {
      const els = document.getElementsByTagName('body')
      if (els.length > 0) {
        const body = els[0]
        if (toggle) {
          body.classList.add('modal-open')
        } else {
          body.classList.remove('modal-open')
        }
      }

      setOpen(toggle)
    },
    [setOpen]
  )

  const onClickModalLauncher = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    toggleModalHandler(!isOpen)
    return false
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')

    if (isOpen) {
      body[0].style.overflow = 'hidden'
    } else {
      body[0].style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <ModalContext.Provider value={contextValue}>
      <Container {...containerProps} data={data} className={`modal modal-${data.display}`}>
        <a
          href='#'
          className='launcher'
          onClick={onClickModalLauncher}
          onKeyUp={(e) => (e.code === 'Enter' ? onClickModalLauncher(e) : null)}>
          <Elements name='Modal Launcher' data={data} field='launcherElements' />
        </a>
        <div
          className={classNames('modal-panel', `modal-panel-${data.displayMode}`, {
            open: isOpen
          })}>
          <div className={`modal-content modal-${data.displayMode}`}>
            {isOpen && (
              <div className='modal-header'>
                <button
                  onClick={() => toggleModalHandler(false)}
                  onKeyUp={(e) => (e.code === 'Enter' ? toggleModalHandler(false) : null)}
                  aria-label='Close Modal'>
                  &#x2715;
                </button>
              </div>
            )}
            <div className='modal-content-body'>
              <Elements name='Modal Body' data={data} field='elements' />
            </div>
            <div className='modal-footer'>
              <Elements name='Modal Footer' data={data} field='footerElements' />
            </div>
          </div>
          <div
            className='modal-curtain'
            onClick={() => toggleModalHandler(!isOpen)}
            onKeyUp={(e) => (e.code === 'Enter' ? toggleModalHandler(!isOpen) : null)}></div>
        </div>
      </Container>
    </ModalContext.Provider>
  )
}
