import React, { Suspense, useContext } from 'react'
import HtmlModel from './models'
import styled from '@emotion/styled'
import { LayoutContext } from '../Layout'
import Handlebars from 'handlebars'
import DOMPurify from 'dompurify'
import { format, formatDistance } from 'date-fns'
import { useAppSelector } from '../../redux'
import { useCart, useMoney } from '@shopify/hydrogen-react'
import { MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types'

interface HtmlProps {
  data?: HtmlModel
  parentData?: any
  context?: { [key: string]: any }
  containerProps?: string[]
}

const HtmlElementWrapper = styled.div<HtmlProps>`
  ul,
  ol {
    list-style: disc;
    padding: 0 2rem;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 0;
  }

  display: ${(props) => (props.data?.style?.visibility !== 'hidden' ? 'block' : 'none')};
  padding-top: ${(props) => props.data?.style?.paddingTop};
  padding-right: ${(props) => props.data?.style?.paddingRight};
  padding-bottom: ${(props) => props.data?.style?.paddingBottom};
  padding-left: ${(props) => props.data?.style?.paddingLeft};
  margin-top: ${(props) => props.data?.style?.marginTop};
  margin-right: ${(props) => props.data?.style?.marginRight};
  margin-bottom: ${(props) => props.data?.style?.marginBottom};
  margin-left: ${(props) => props.data?.style?.marginLeft};
  background-color: ${(props) => props.data?.style?.backgroundColor};
  border-left: ${(props) => {
    // returning '' fixes an issue when doing dom-to-img for preview images
    if ([0, '0', '0px'].includes(props.data?.style?.borderLeft as string)) return ''
    return `${props.data?.style?.borderLeft} solid ${props.data?.style?.borderColor}`
  }};
  border-top: ${(props) => {
    // returning '' fixes an issue when doing dom-to-img for preview images
    if ([0, '0', '0px'].includes(props.data?.style?.borderTop as string)) return ''
    return `${props.data?.style?.borderTop} solid ${props.data?.style?.borderColor}`
  }};
  border-right: ${(props) => {
    // returning '' fixes an issue when doing dom-to-img for preview images
    if ([0, '0', '0px'].includes(props.data?.style?.borderRight as string)) return ''
    return `${props.data?.style?.borderRight} solid ${props.data?.style?.borderColor}`
  }};
  border-bottom: ${(props) => {
    // returning '' fixes an issue when doing dom-to-img for preview images
    if ([0, '0', '0px'].includes(props.data?.style?.borderBottom as string)) return ''
    return `${props.data?.style?.borderBottom} solid ${props.data?.style?.borderColor}`
  }};

  border-radius: ${(props) => props.data?.style?.borderRadius};
  width: ${(props) =>
    props.data?.style?.widthType === 'auto' ? 'auto' : props.data?.style?.widthValue};
  height: ${(props) =>
    props.data?.style?.heightType === 'auto' ? 'auto' : props.data?.style?.heightValue};
`

Handlebars.registerHelper('tagPrefix', (prefix: string, tags: string[]) => {
  if (prefix && Array.isArray(tags)) {
    const tag = tags.find((t) => t.startsWith(prefix))
    if (tag) {
      return tag.replace(prefix, '')
    }
  }
})

//returns 'about 1 month ago' or '3 days ago'
Handlebars.registerHelper('timeAgo', (date: string) => {
  // return empty string if date is null
  return date ? formatDistance(new Date(date), new Date(), { addSuffix: true }) : ''
})

//allows user to specify format, or defaults to 'Apr 1, 2022'
Handlebars.registerHelper('dateFormat', (date: string, dateFormat?: string) => {
  // return empty string if date is null
  return date ? format(new Date(date), typeof dateFormat === 'string' ? dateFormat : 'PP') : ''
})

//compare two values in handlebars
Handlebars.registerHelper('compare', function (lvalue, operator, rvalue, options) {
  let operators: { [key: string]: any }
  let result

  if (arguments.length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters")
  }

  if (options === undefined) {
    options = rvalue
    rvalue = operator
    operator = '==='
  }

  operators = {
    '==': function (l: any, r: any) {
      return l == r
    },
    '===': function (l: any, r: any) {
      return l === r
    },
    '!=': function (l: any, r: any) {
      return l != r
    },
    '!==': function (l: any, r: any) {
      return l !== r
    },
    '<': function (l: any, r: any) {
      return l < r
    },
    '>': function (l: any, r: any) {
      return l > r
    },
    '<=': function (l: any, r: any) {
      return l <= r
    },
    '>=': function (l: any, r: any) {
      return l >= r
    },
    typeof: function (l: any, r: any) {
      return typeof l == r
    }
  }

  const op =
    new DOMParser().parseFromString(operator, 'text/html').documentElement.textContent || '=='
  if (!operators[op]) {
    throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator)
  }

  result = operators[op](lvalue, rvalue)

  if (result) {
    //@ts-ignore
    return options.fn(this)
  } else {
    //@ts-ignore
    return options.inverse(this)
  }
})

export default function Html({ data, context, containerProps }: HtmlProps) {
  const productCache = useAppSelector((state) => state.product.products)
  const products = useAppSelector((state) => state.collectionElement.products)
  const totalProducts = useAppSelector((state) => state.collectionElement.totalProducts)
  const globalContext = useAppSelector((state) => state.app.config.context)
  const parentContext = useContext(LayoutContext)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  const cart = useCart()

  let html = ''

  if (data) {
    try {
      const template = Handlebars.compile(data?.html, { noEscape: true })

      let extraContext: { [key: string]: any } = {}

      const pageType = globalContext?.pageType || parentContext?.__type.toLowerCase()

      switch (pageType) {
        case 'product':
          if (globalContext?.productId) {
            extraContext['product'] = productCache[globalContext?.productId]
          }
          break
        case 'collection':
          extraContext['products'] = products
          extraContext['totalProducts'] = totalProducts
          break
      }

      if (enableHeadlessCommerce) {
        if (cart && cart.cost && cart.cost.subtotalAmount) {
          const subtotal = +(cart.cost.subtotalAmount.amount || 0)
          
          const formatter = Intl.NumberFormat('default', {
            style: 'currency',
            currency: cart.cost.subtotalAmount.currencyCode,
            maximumFractionDigits: 2
          })

          extraContext.cart = {
            subtotal: formatter.format(subtotal),
            totalQuantity: cart.totalQuantity || 0
          }
        }
      }

      const templateContext = context
        ? {
            parent: parentContext || {},
            ...(context || {}),
            ...extraContext
          }
        : {
            ...(parentContext || {}),
            ...extraContext
          }

      html = template(templateContext)
    } catch (e) {
      console.log(e)
    }
  }

  const cleanHtml = DOMPurify.sanitize(html, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })

  return (
    <HtmlElementWrapper
      dangerouslySetInnerHTML={{ __html: cleanHtml || '' }}
      data={data}
      {...containerProps}></HtmlElementWrapper>
  )
}
