import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import { RootState } from '..'
import queryString from 'query-string'
import { sendEvent, toggleLoading } from './app'
import { getWishListItems } from './wishlist'
import { IntlPrices} from '../../types'
import { getInternationalPrices } from '../../lib/helpers'

interface CollectionState {
  loading: boolean
  params: { [key: string]: string[] }
  config: any
  sortBy: string
  search: string
  baseCollection: string
  pageIndex: number
  products: any[]
  totalProducts: number
  intlPrices: IntlPrices[]
  content: any
  facetedData: any
}

const initialState: CollectionState = {
  loading: false,
  params: {},
  config: {},
  sortBy: 'manual',
  search: '',
  baseCollection: '',
  pageIndex: 1,
  products: [],
  intlPrices: [],
  totalProducts: 0,
  content: null,
  facetedData: null
}

let cancelToken: CancelTokenSource | null = null

/**
 * Handles loading collection data into redux
 */
export const load = createAsyncThunk(
  'collection/load',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    const config = state.collection.config

    if (cancelToken) {
      // cancel previous requests
      cancelToken.cancel()
    }

    dispatch(toggleLoading(true))
    cancelToken = axios.CancelToken.source()

    const filterState: {
      options: string[]
      tags: string[]
      prices: string[]
    } = {
      options: [],
      tags: [],
      prices: []
    }

    Object.entries(state.collection.params).forEach(([key, value]) => {
      // get filter config for key
      const filterConfig = config.filters.filter((f: any) => f.name === key)
      if (filterConfig.length > 0) {
        switch (filterConfig[0].type) {
          case 'tag':
            filterState.tags = [...filterState.tags, ...value.map((v) => `${key}:${v}`)]
            break
          case 'option':
            // option needs key:value pair
            filterState.options = [...filterState.options, ...value.map((v) => `${key}:${v}`)]
            break
          case 'price':
            filterState.prices = [...filterState.prices, ...value]
            break
          default:
            break
        }
      }
    })

    try {
      let facets: string[] = []
      config.filters.forEach((filter: any) => {
        if (filter.type === 'tag') {
          if (filter.mode === 'automatic') {
            facets.push(filter.prefix)
          }
        }
      })

      const search = state.collection.search
      const path = '/products/es/search'
      const response: any = await axios.get(path, {
        params: {
          s: search.trim() !== '' ? search : undefined,
          ...filterState,
          baseCollection: state.collection.baseCollection,
          pageIndex: state.collection.pageIndex,
          sortBy: search.trim() !== '' ? undefined : state.collection.sortBy,
          pageSize: config.pagination.pageSize || 10,
          faceted: true,
          facetedTagSet: facets,
          outOfStock: config.showOutofStockItems
        },
        cancelToken: cancelToken.token
      })

      cancelToken = null
      const items = response.data.items

      dispatch(toggleLoading(false))

      // find all ids including within product family
      const productIds: string[] = []

      items.forEach((item: any) => {
        productIds.push(item.sourceId)

        item.family.forEach((family: any) => {
          productIds.push(family.sourceId)
        })
      })

      const uniqueProductIds = [...new Set(productIds)]

      dispatch(getWishListItems({ productIds: uniqueProductIds }))

      const iso: string = state.app.config.currency?.isoCountryCode ?? ''
      if (iso && iso != 'US')  {
        const storeToken: string = state.app.config.shopify?.storefront?.token ?? ''
        const shopUrl: string = state.app.config.shopify?.storefront?.shopUrl ?? ''
        //get product ids for every product (to send to graphql)
        const gidPids: string[] = items.map((product: any) => `gid://shopify/Product/${product.sourceId}` )
        //eliminate duplicate product id's, if any
        let uniqueGidPids: Set<string> = new Set(gidPids)
        //add unqiue product id's for every variant (family)
        items.forEach((product: any) => {
          product?.family?.map((item: any) => {
            uniqueGidPids.add(`gid://shopify/Product/${item.sourceId}`)
          })
        })
        //attach international prices for all unqiue product id's to results
        response.data.intlPrices = await getInternationalPrices(Array.from(uniqueGidPids), iso, storeToken, shopUrl)
        console.log(`retrieved latest ${iso} pricing.`)
      }

      return response.data
    } catch (e) {
      console.log(e)
      rejectWithValue('Could not get collection data')
    }
  }
)

/**
 * Resets entire store
 */
export const resetFilters = createAsyncThunk('collection/resetFilters', async (_, { dispatch }) => {
  window.history.pushState([], '', window.location.href.split('?')[0])
  dispatch(sendEvent({ source: `catalog.filter.reset` }))
  return
})

export const setFilters = createAsyncThunk(
  'collection/setFilters',
  async ({ filter, items }: { filter: any, items: any }, { getState, dispatch }) => {
    const state = getState() as RootState

    dispatch(setPageIndex(1))

    if (items.length > 0) {
      dispatch(
        sendEvent({
          source: `catalog.filter.select`,
          data: {
            filter,
            selection: items,
            totalProducts: state.collection.totalProducts
          }
        })
      )
    }

    let paramName = filter.name
    if (filter.type === 'sorter') {
      paramName = 'sortBy'
      dispatch(setSortBy(items))
    }

    let newParam = { ...state.collection.params }
    newParam[paramName] = items

    // // update browser history
    const qs = queryString.stringify(newParam, { arrayFormat: 'bracket' })
    window.history.pushState(newParam, '', window.location.href.split('?')[0] + (qs && '?' + qs))
  }
)

const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.params = action.payload
    },
    setConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setBaseCollection: (state, action: PayloadAction<string>) => {
      state.baseCollection = action.payload
    },
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(load.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(load.fulfilled, (state, action) => {
      state.loading = false
      state.products = action.payload.items
      state.intlPrices = action.payload.intlPrices
      state.totalProducts = action.payload.total
      state.content = action.payload.content
      state.facetedData = action.payload.facetedData
    })
    builder.addCase(load.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(resetFilters.fulfilled, (state, action) => {
      state.facetedData = initialState.facetedData
      state.products = initialState.products
      state.pageIndex = initialState.pageIndex
      state.content = initialState.content
      state.params = initialState.params
      state.search = initialState.search
      state.sortBy = initialState.sortBy
      state.totalProducts = initialState.totalProducts
    })
  }
})

export const { setParams, setConfig, setSortBy, setSearch, setBaseCollection, setPageIndex } =
  collectionSlice.actions
export default collectionSlice.reducer
