import React, { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import SideCartModel from './models'
import { Elements } from '..'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../redux'
import { setCartOpen } from '../../redux/slices/cart'
import { useCart } from '@shopify/hydrogen-react'

interface Props {
  data: SideCartModel
  containerProps: any
}

const Container = styled.div<{ data: SideCartModel }>`
  .side-cart-modal-component.launcher {
    display: none;
  }

  &.side-cart-modal {
    .side-cart-modal-component.modal-panel {
      position: fixed;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      z-index: 21474836479989;
      justify-content: ${(props) => (props.data.displayMode === 'popup' ? 'center' : 'unset')};

      .side-cart-modal-component.modal-content {
        position: relative;
        display: ${(props) => (props.data.displayMode === 'popup' ? 'none' : 'flex')};
        flex-direction: column;
        z-index: 2;
        background-color: white;
        transition: linear;
        transition-duration: 200ms;
        pointer-events: all;
        margin: 0;
        width: 100%;
        max-height: 100vh;

        &.side-cart-modal-component.modal-left {
          position: fixed;
          width: ${(props) => props.data.width};
          left: 0;
          transform: translate(-${(props) => props.data.width});
          top: 0;
          bottom: 0;
          height: 100%;

          .side-cart-modal-component.modal-header {
            text-align: right;
          }
        }

        &.side-cart-modal-component.modal-right {
          position: fixed;
          width: ${(props) => props.data.width};
          right: 0;
          transform: translate(${(props) => props.data.width});
          top: 0;
          bottom: 0;
          height: 100%;

          .side-cart-modal-component.modal-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        }

        &.side-cart-modal-component.modal-popup {
          justify-self: center;
          align-self: center;
          width: ${(props) => props.data.width};
          height: ${(props) => props.data.height};

          .side-cart-modal-component.modal-header {
            position: absolute;
            top: 0;
            z-index: 10;
          }
        }

        .side-cart-modal-component.modal-header {
          font-size: ${(props) => props.data.titleFontSize};
          font-weight: ${(props) => props.data.titleFontWeight};
          padding-top: ${(props) => props.data.paddingTop};
          padding-right: ${(props) => props.data.paddingRight};
          padding-bottom: ${(props) => props.data.paddingBottom};
          padding-left: ${(props) => props.data.paddingLeft};

          button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 25px;
            color: #888;
          }
        }

        .side-cart-modal-component.modal-content-body {
          overflow-y: auto;
          flex-grow: 1;
        }

        .side-cart-modal-component.modal-footer {
          flex-shrink: 1;
          width: calc(100% - 20px);
          background-color: white;
          padding: 10px;
        }
      }

      @media screen and (max-width: 768px) {
        > .side-cart-modal-component.modal-content {
          width: ${(props) => props.data.mobileWidth};

          &.side-cart-modal-component.modal-right {
            width: ${(props) => props.data.mobileWidth};
            transform: translate(${(props) => props.data.mobileWidth});
          }
        }
      }

      .side-cart-modal-component.modal-curtain {
        position: absolute;
        background-color: black;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: -1;
        display: none;
        pointer-events: all;
      }
    }

    .side-cart-modal-component.modal-panel.open {
      .side-cart-modal-component.modal-content {
        &.side-cart-modal-component.modal-left,
        &.side-cart-modal-component.modal-right {
          transform: unset;
        }

        display: flex;
      }

      .side-cart-modal-component.modal-curtain {
        display: block;
      }
    }
  }

  .side-cart-modal-component.launcher {
    display: inline-block;
    pointer-events: all;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    position: relative;

    > div {
      pointer-events: none;
    }

    .cart-quantity {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      background-color: ${(props) => props.data.qtyBadgeBackgroundColor};
      color: ${(props) => props.data.qtyBadgeColor};
      padding: 2px 6px;
      border-radius: 199px;
      font-size: ${(props) => props.data.qtyBadgeFontSize};
      transform: translateX(25%);
    }
  }
`

export const ModalContext = React.createContext<{ close: () => void } | null>(null)

export default function SideCart({ data, containerProps }: Props) {
  const isOpen = useAppSelector((state) => state.cart.isOpen)
  const admin = useAppSelector((state) => state.app.isAdmin)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  const cart = useCart()
  const dispatch = useAppDispatch()

  const toggleModalHandler = useCallback((toggle: boolean) => {
    const els = document.getElementsByTagName('body')
    if (els.length > 0) {
      const body = els[0]
      if (toggle) {
        body.classList.add('cart-modal-open')
      } else {
        body.classList.remove('cart-modal-open')
      }
    }

    dispatch(setCartOpen(toggle))
  }, [])

  const onClickModalLauncher = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    toggleModalHandler(!isOpen)
    return false
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')

    if (isOpen) {
      body[0].style.overflow = 'hidden'
    } else {
      body[0].style.overflow = 'unset'
    }
  }, [isOpen])

  if (admin && !enableHeadlessCommerce) {
    return <div>Headless Commerce not enabled.</div>
  }

  if (!enableHeadlessCommerce) {
    return <></>
  }

  const cartQuantity = admin ? 10 : cart.totalQuantity || 0

  return (
    <Container {...containerProps} data={data} className={`side-cart-modal`}>
      <a
        href='#'
        className='side-cart-modal-component launcher'
        onClick={onClickModalLauncher}
        onKeyUp={(e) => (e.code === 'Enter' ? onClickModalLauncher(e) : null)}
        title={data.name}>
        <Elements name='Modal Launcher' data={data} field='launcherElements' />
        {data.showCartQuantity && <div className='cart-quantity'>{cartQuantity}</div>}
      </a>
      <div
        className={classNames(
          'side-cart-modal-component modal-panel',
          `modal-panel-${data.displayMode}`,
          {
            open: isOpen
          }
        )}>
        <div className={`side-cart-modal-component modal-content modal-${data.displayMode}`}>
          {isOpen && (
            <div className='side-cart-modal-component modal-header'>
              <button
                onClick={() => toggleModalHandler(false)}
                onKeyUp={(e) => (e.code === 'Enter' ? toggleModalHandler(false) : null)}
                aria-label='Close Modal'>
                &#x2715;
              </button>
              <div>{data.name}</div>
            </div>
          )}
          <div className='side-cart-modal-component modal-content-body'>
            <Elements name='Modal Body' data={data} field='elements' />
          </div>
          <div className='side-cart-modal-component modal-footer'>
            <Elements name='Modal Footer' data={data} field='footerElements' />
          </div>
        </div>
        <div
          className='side-cart-modal-component modal-curtain'
          onClick={() => toggleModalHandler(!isOpen)}
          onKeyUp={(e) => (e.code === 'Enter' ? toggleModalHandler(!isOpen) : null)}></div>
      </div>
    </Container>
  )
}
