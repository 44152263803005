import React from 'react'
import { useRef, useEffect } from 'react'
import axios, { CancelToken, Cancel, CancelTokenSource } from 'axios'
import { IntlPrices} from '../types'

export function toFilterObjectState(params: any, filters: any) {
  let newObjectState: any = {}
  params
    .map((param: any) => param.split('__'))
    .forEach((o: any) => {
      const [key, values] = o
      // find filter
      const filter = filters.find((f: any) => f.name === key)
      // find option matching value
      values.split(':').forEach((value: any) => {
        const match = filter.options.find((o: any) => o.value === value)
        if (Array.isArray(newObjectState[key])) {
          newObjectState[key].push(match)
        } else {
          newObjectState[key] = [match]
        }
      })
    })

  return newObjectState
}

export function fromFilterObjectState(objectState: any) {
  const values = []
  for (const [key, set] of Object.entries<[key: string, set: any[]]>(objectState)) {
    if (set.length > 0) {
      const params = []
      for (const s of set) {
        if (s) {
          //@ts-ignore
          params.push(s.value)
        }
      }
      const paramValue = params.join(':')
      if (paramValue) {
        values.push(`${key}__${paramValue}`)
      }
    }
  }

  return values
}

// https://stackoverflow.com/questions/58442168/why-useeffect-doesnt-run-on-window-location-pathname-changes
export function useReactQuery() {
  const [query, setQuery] = React.useState(window.location.search)
  const listenToPopstate = () => {
    const winQuery = window.location.search
    setQuery(winQuery)
  }

  React.useEffect(() => {
    // monkey patch push state in order to capture url changes
    var pushState = window.history.pushState
    window.history.pushState = function (state) {
      //@ts-ignore
      if (typeof window.history.onpushstate == 'function') {
        //@ts-ignore
        window.history.onpushstate({ state: state })
      }

      setTimeout(listenToPopstate, 0)
      //@ts-ignore
      return pushState.apply(window.history, arguments)
    }

    window.addEventListener('popstate', listenToPopstate)

    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])

  return query
}

// ADAPTED FROM : https://dev.to/tmns/usecanceltoken-a-custom-react-hook-for-cancelling-axios-requests-1ia4
/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelToken: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 */
export const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource | null>(null)
  const newCancelToken = () => {
    if (axiosSource.current) {
      axiosSource.current.cancel()
    }

    axiosSource.current = axios.CancelToken.source()
    return axiosSource.current.token
  }

  useEffect(() => {
    if (axiosSource.current) axiosSource.current.cancel()
  }, [])

  return { newCancelToken }
}

/**
 * Converts js object to a basic json object
 * @param obj
 * @returns
 */
export function objectToInitialState(obj: any) {
  return JSON.parse(JSON.stringify(new obj()))
}

/**
 * Determine whether the given `promise` is a Promise.
 *
 * @param {*} promise
 *
 * @returns {Boolean}
 */
export function isPromise(promise: Promise<any> | Function): boolean {
  //@ts-ignore
  return !!promise && typeof promise.then === 'function'
}


/**
 * Recursively finds an element type
 * @param data 
 * @param elementName 
 * @param elements 
 * @returns 
 */
export function findNestedElement<T>(data: any, elementName: string, elements: T[] = []) {
  if (data) {
    for (const [key, val] of Object.entries(data)) {
      if (key === '__type' && val === elementName) {
        elements.push(data)
      } else if (Array.isArray(val)) {
        //need to loop through each element in the array
        for (const item of val) {
          findNestedElement(item, elementName, elements)
        }
      } else if (typeof val === 'object') {
        // objects need to be processed recursively
        findNestedElement<T>(val, elementName, elements)
      }
    }
  }

  return elements

}

export enum ImageResizeFormat {
  crop = 'crop',
  fitIn = 'fit-in'
}

/**
 * Takes an image url and builds a resized url
 */
export function getResizedImageSrc(
  originalSource: string,
  format: ImageResizeFormat,
  height: number,
  width: number
): string {
  // don't resize svg images
  if (originalSource?.endsWith('.svg') || originalSource?.endsWith('.gif') || !originalSource) {
    return originalSource
  }

  const proto = originalSource.startsWith('https://') ? 'https://' : 'http://'
  const sourceWithoutProto = originalSource.replace(proto, '')
  const [urlPath, queryString] = sourceWithoutProto.split('?')
  const parts = urlPath.split('/')
  const domain = parts.shift()
  const imagePath = parts.join('/')

  const newSourceParts = [domain]

  // only include the format with using fit-in
  if (format === ImageResizeFormat.fitIn) {
    newSourceParts.push(format)
  }

  // apply height and width to url
  newSourceParts.push(`${width}x${height}`)
  newSourceParts.push(imagePath)

  // build final source url
  let newSource = proto + newSourceParts.join('/')
  if (queryString) {
    newSource += '?' + queryString
  }

  return newSource
}

//https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
export function throttle(callback: Function, time: number) {
  var lastTime = 0;
  return function (...args: any[]) {
      const now: number = new Date().getTime();
      if (now - lastTime >= time) {
        callback(...args);
        lastTime = now;
      }
  };
}
export const appendScriptTagToBody = ({
  src,
  id,
  onload = () => {}
}: {
  src: string
  id: string
  onload: Function
}) => {
  const script = document.createElement('script')
  script.src = src
  script.id = id
  script.onload = () => {
    onload()
  }
  document.body.appendChild(script)
}

/**
 * Truncates numbers like 2000 and 2,000,000 to 2K and 2M respectively
 */
export function truncateLargeNumbers(count: number = 0){
  const million = 1000000
  const thousand = 1000

  if(count / million > 1){
    return  `${formatNumberToDecimalPlaces((count / million), 1)}M`;
  }
  if(count / thousand > 1){
    return `${formatNumberToDecimalPlaces((count / thousand), 1)}K`;
  }
  return count;
}

function formatNumberToDecimalPlaces(num: number, numOfDigitsAfterDecimal = 2){
  if(!Number.isNaN(num)){
    const numString = num.toString()
    const formattedNumString = numString.slice(0, (numString.indexOf(".") + 1) + numOfDigitsAfterDecimal)
    return Number.parseFloat(formattedNumString)
  }
  return num;
}

export async function getInternationalPrices(
  pids: string[],
  iso2code: string,
  storeToken: string,
  shopUrl: string
): Promise<IntlPrices[]> {
  const query = {
    query: `query myquery @inContext(country: ${iso2code}) {
      nodes(ids: ["${pids.join('","')}"]) {
        ... on Product {
          id
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          variants(first: 1) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }`
  }

  let intlPrices: IntlPrices[] = []
  const response = await graphQl(query, iso2code, storeToken, shopUrl)
  if (response?.data?.data?.nodes && response.data.data.nodes.length > 0) {
    for (const item of response.data.data.nodes) {
      if (item) {
        intlPrices.push({
          sourceId: item.id.replace('gid://shopify/Product/', ''),
          compareAtPriceV2: {
            amount: item.variants?.edges[0]?.node?.compareAtPriceV2?.amount,
            currencyCode: item.variants?.edges[0]?.node?.compareAtPriceV2?.currencyCode
          },
          priceV2: {
            amount: item.variants?.edges[0]?.node?.priceV2?.amount,
            currencyCode: item.variants?.edges[0]?.node?.priceV2?.currencyCode
          },
          priceRange: {
            minVariantPrice: {
              amount: item.priceRange.minVariantPrice.amount,
              currencyCode: item.priceRange.minVariantPrice.currencyCode
            },
            maxVariantPrice: {
              amount: item.priceRange.maxVariantPrice.amount,
              currencyCode: item.priceRange.maxVariantPrice.currencyCode
            }
          }
        })
      }
    }
  }
  return intlPrices
}

export async function graphQl(
  query: any,
  iso2code: string,
  storeToken: string,
  shopUrl: string
): Promise<any> {
  //any blank vars and return Empty
  if (!iso2code || !storeToken || !shopUrl) {
    return []
  }
  
  const axiosClient = axios.create({
    baseURL: '',
    params: null,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeToken,
    }
  })

  delete axiosClient.defaults.headers['x-session-id']
  delete axiosClient.defaults.headers.common['nogin-no-customer-lookup']

  try {
    const apiVersion: string = import.meta.env.VITE_SHOPIFY_STOREFRONT_API_VERSION as string
    return await axiosClient.post(`${shopUrl}/api/${apiVersion}/graphql.json`, query)
  } catch (e) {
    console.log(e)
  }
}

//https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
export function debounce(callback: Function, time: number, isImmediate?: boolean) {
  let timeout: number | null;
  return function(...args: any[]){
    if (typeof timeout === "number") {
      clearTimeout(timeout);
    }

    timeout = window.setTimeout(function() {
      timeout = null;
      if (!isImmediate){
        callback(...args)
      }
    }, time)

    if (isImmediate && !timeout){
      callback(...args)
    }
  }
}

/*
 * debounce based on whether the payload is a duplicate.
 * also has timeout support.
 */
let previousEvents: any[] = []
export const debounceWithCompare = async (
  callback: Function,
  payload: any,
  timer: number,
  excludeFromHash: string[] = []
) => {
  let key = ''
  if (excludeFromHash && excludeFromHash.length > 0) {
    //things like 'meta.rid' cause the payload to have different hash value
    //   even though all the other data is the same -- duplicate)
    const payloadToCompare = Object.assign({}, payload)
    //exclude all property names in the array from the hashed object
    excludeFromHash.forEach( (key: string) => {
      delete payloadToCompare[key]
    })
    key = await hash256(JSON.stringify(payloadToCompare))
  } else {
    key = await hash256(JSON.stringify(payload))
  }
  if (!previousEvents.includes(key)) {
    callback(payload)
  }
  previousEvents.push(key)

  //clear memory of previous events
  if (timer > 0) {
    setTimeout(() => {
      previousEvents = []
    }, timer)
  }
}

/*
 * simple 256 hash for comparison purposes only (not meant for secure cryptography)
 */
export const hash256 = async (input: any): Promise<string> => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('')
  return hash
}

/**
 *
 * Removes the 'https://cdn.shopify.com/s/files/(any number of digit or slashes)' prefix from a url
 * and makes the path relative to the domain.
 *
 * i.e. An input of 'https://cdn.shopify.com/s/files/123/345/products/my-image'
 * returns '/cdn/shop/products/my-image' so that the image is relative to the current domain.
 *
 * This allow us to leverage Cloudflare for image requests
 */
export const getRelativeShopifyCDNPath = (url: string = '') => {
  if(window.location.hostname.includes('localhost') || window.location.hostname.includes('nogin')){
    return url
  }
  return url?.replace(/(https?:\/\/)?cdn.shopify.com\/s\/files[\/\d]*/, '/cdn/shop/') || url
}
