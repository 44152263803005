import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '..'

interface InitialCartState {
  isOpen: boolean
}

const initialState: InitialCartState = {
  isOpen: false
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartOpen: (state, action) => {
      state.isOpen = action.payload
    }
  },
  extraReducers: (builder) => {
  }
})

export const { setCartOpen } = cartSlice.actions
export default cartSlice.reducer
