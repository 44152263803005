import React from 'react'
import SectionModel from './models'
import { Elements } from '../index'
import styled from '@emotion/styled'

interface SectionProps {
  data?: SectionModel
  containerProps?: any
}

const SectionWrapper = styled.div<SectionProps>`
  width: ${(props) =>
    props.data?.style?.widthType === 'auto' ? 'auto' : props.data?.style?.widthValue};
  height: ${(props) =>
    props.data?.style?.heightType === 'auto' ? 'auto' : props.data?.style?.heightValue};
  top: ${(props) => (props.data?.style?.topType === 'auto' ? 'auto' : props.data?.style?.topValue)};
  left: ${(props) =>
    props.data?.style?.leftType === 'auto' ? 'auto' : props.data?.style?.leftValue};
  padding-top: ${(props) => props.data?.style?.paddingTop};
  padding-right: ${(props) => props.data?.style?.paddingRight};
  padding-bottom: ${(props) => props.data?.style?.paddingBottom};
  padding-left: ${(props) => props.data?.style?.paddingLeft};
  margin-top: ${(props) => props.data?.style?.marginTop};
  margin-right: ${(props) => props.data?.style?.marginRight};
  margin-bottom: ${(props) => props.data?.style?.marginBottom};
  margin-left: ${(props) => props.data?.style?.marginLeft};
  background-color: ${(props) => props.data?.style?.backgroundColor};

  ${(props) => {
    if(props?.data?.style == null) return ''

    const { borderColor, 
      borderThickness,
      borderTop, 
      borderLeft, 
      borderRight, 
      borderBottom, 
      borderRadius
    } = props?.data?.style

    // For Snapshot Previews:
      // Conditionally render the border properties only if they're filled in
    if(!borderColor || borderColor.length === 0) return ''
    if(
      !['', 0, '0', '0px'].includes(borderTop) && 
      !['', 0, '0', '0px'].includes(borderLeft) && 
      !['', 0, '0', '0px'].includes(borderRight) && 
      !['', 0, '0', '0px'].includes(borderBottom)
    ) return ''
    
    return `
    border-width: ${borderThickness && borderThickness !== '0px' ? borderThickness : `${borderTop} ${borderRight} ${borderBottom} ${borderLeft}` };
    border-style: solid;
    border-color: ${borderColor};
    border-radius: ${borderRadius};  
    `
  }}

  
  display: ${(props) => {
    if (props.data?.desktopStyleEnabled) {
      if (props.data?.desktopStyle.visibility === 'visible') {
        return 'block'
      }
      return 'none'
    } else {
      if (props.data?.style.visibility === 'visible') {
        return 'block'
      }
      return 'none'
    }
  }};

  @media (max-width: 768px) {
    display: ${(props) => props.data?.style.visibility === 'visible' ? 'block' : 'none'};
  }

  @media (min-width: 768px) {
    &.desktop {
      width: ${(props) =>
        props.data?.desktopStyle?.widthType === 'auto' ? 'auto' : props.data?.desktopStyle?.widthValue};
      height: ${(props) =>
        props.data?.desktopStyle?.heightType === 'auto' ? 'auto' : props.data?.desktopStyle?.heightValue};
      top: ${(props) => (props.data?.desktopStyle?.topType === 'auto' ? 'auto' : props.data?.desktopStyle?.topValue)};
      left: ${(props) =>
        props.data?.desktopStyle?.leftType === 'auto' ? 'auto' : props.data?.desktopStyle?.leftValue};
      padding-top: ${(props) => props.data?.desktopStyle?.paddingTop};
      padding-right: ${(props) => props.data?.desktopStyle?.paddingRight};
      padding-bottom: ${(props) => props.data?.desktopStyle?.paddingBottom};
      padding-left: ${(props) => props.data?.desktopStyle?.paddingLeft};
      margin-top: ${(props) => props.data?.desktopStyle?.marginTop};
      margin-right: ${(props) => props.data?.desktopStyle?.marginRight};
      margin-bottom: ${(props) => props.data?.desktopStyle?.marginBottom};
      margin-left: ${(props) => props.data?.desktopStyle?.marginLeft};
      background-color: ${(props) => props.data?.desktopStyle?.backgroundColor};
      border-width: ${(props) => props.data?.desktopStyle?.borderThickness !== '0px' ? props.data?.desktopStyle?.borderThickness : `${props.data?.desktopStyle?.borderTop} ${props.data?.desktopStyle?.borderRight} ${props.data?.desktopStyle?.borderBottom} ${props.data?.desktopStyle?.borderLeft}` };
      border-style: solid;
      border-color: ${(props) => props.data?.desktopStyle?.borderColor};
      border-radius: ${(props) => props.data?.desktopStyle?.borderRadius};
    }
  }

  > * > div.elements-wrapper {
    display: flex;
    flex-direction: ${(props) => props.data?.format};
    justify-content: ${(props) => props.data?.alignment};
    gap: ${(props) => props.data?.gap};
  }
`

export default function Section({ data, containerProps }: SectionProps) {
  const props = {
    className: `element_section ${containerProps?.className || ''} ${data?.desktopStyleEnabled && 'desktop'} ${data?.contained ? 'container' : ''}`
  }

  return (
    <SectionWrapper data={data} {...containerProps} {...props}>
      <Elements name='Section' data={data} field='elements' />
    </SectionWrapper>
  )
}
