import React, { useCallback, useEffect, useState } from 'react'
import SnippetModel from '../../elements/Snippet/models'
import { useAppDispatch, useAppSelector } from '../../redux'
import {
  DynamicPlacementModelWithSnippet,
  loadPlacements,
  togglePreview,
  updateActivePlacements
} from '../../redux/slices/app'
import insertQuery from 'insertion-query'
import { SnippetPortal } from './SnippetPortal'
import cookie from 'js-cookie'
import { getWishListItems } from '../../redux/slices/wishlist'


export default function PlacementManager() {
  const dispatch = useAppDispatch()
  const debug = useAppSelector((state) => state.app.debug)
  const isAdmin = useAppSelector((state) => state.app.isAdmin)
  const loading = useAppSelector((state) => state.app.loading)
  const placements = useAppSelector((state) => state.app.placements)
  const [snippets, setSnippets] = useState<{ [key: string]: SnippetModel }>({})
  const _activePlacements = useAppSelector((state) => state.app.activePlacements)
  const isWishListItemsLoaded = useAppSelector((state) => state.wishlist.customer.isWishListItemsLoaded)

  const createPlacement = useCallback(
    (placement: DynamicPlacementModelWithSnippet): string | false => {
      // find the target element based on configured placement model
      const targets = document.querySelectorAll(placement.target)
      if (targets.length > 0) {
        const placementSelector = `placement-${placement.slug}`

        // remove existing placements
        const existingPlacements = document.querySelectorAll('.' + placementSelector)
        existingPlacements.forEach((el) => el.remove())

        // if our target exists, insert empty container div
        // into correct position
        const placementDiv = document.createElement('div')
        placementDiv.classList.toggle(placementSelector)
        placementDiv.style.display = 'none'
        
        if (debug) {
          placementDiv.style.border = '2px solid #f35b78'
          //placementDiv.innerText = placement.name
        }

        if(!isWishListItemsLoaded && placement?.requestsCustomerWishlistData){
          dispatch(getWishListItems({}))
        }

        targets.forEach((target) => {
          switch (placement.insertPosition) {
            case 'before':
              target.insertAdjacentElement('beforebegin', placementDiv)
              break
            case 'replace':
              target.innerHTML = ''
              target.appendChild(placementDiv.cloneNode())
              break
            default:
              target.insertAdjacentElement('afterend', placementDiv)
          }
        })

        return placementSelector
      }

      return false
    },
    [debug]
  )

  useEffect(() => {
    if (!isAdmin) {
      const url = new URL(window.location.href)
      let previewId: string | null | undefined = url.searchParams.get('nogin_pid')

      // also read cookie if id not provided
      if (!previewId) {
        previewId = cookie.get('__nogin_pid')
      }

      dispatch(togglePreview(previewId))
      if (previewId) {
        cookie.set('__nogin_pid', previewId!)
      }

      dispatch(loadPlacements({ previewId }))
    }
  }, [])

  useEffect(() => {
    const maxExecutions = 20
    let currentExecution = 1
    const remainingPlacements = Object.values(placements)
    let activePlacements: string[] = []
    let activeSnippets: { [key: string]: SnippetModel } = {}

    const waitForElementReady = () => {
      remainingPlacements.forEach((placement, i) => {
        if (placement.target) {
          // find the target element based on configured placement model
          const targetId = createPlacement(placement)

          if (targetId) {
            // snippet exists for this placement
            if (placement._snippet) {
              activeSnippets[targetId] = placement._snippet
            }

            if (activePlacements.indexOf(placement.slug) === -1) {
              activePlacements.push(placement.slug)
            }

            // remove placement
            remainingPlacements.splice(i, 1)
          }
        } else {
          // remove placement
          remainingPlacements.splice(i, 1)
        }

        currentExecution++
      })

      if (remainingPlacements.length > 0 && currentExecution <= maxExecutions) {
        setTimeout(() => {
          waitForElementReady()
        }, 50)
      } else {
        // done loading all placements
        dispatch(updateActivePlacements(activePlacements))
        setSnippets(activeSnippets)
      }
    }

    if (!loading && Object.keys(placements).length > 0) {
      waitForElementReady()
    }

    return () => {
      // cleanup
      Object.entries(placements).forEach(([slug, placement]) => {
        if (placement.target) {
          const placementId = `placement-${placement.slug}`
          const placementDiv = document.getElementById(placementId)
          placementDiv?.remove()
        }
      })
    }
  }, [placements, debug, loading])

  useEffect(() => {
    // watch for dom insertions
    Object.entries(placements).forEach(([_, placement]) => {
      ;(insertQuery(placement.target) || []).every(function (element: any) {
        if (!_activePlacements.includes(placement.slug)) {
          // find the target element based on configured placement model
          const targetId = createPlacement(placement)

          if (targetId) {
            // snippet exists for this placement
            if (placement._snippet) {
              setSnippets({
                ...snippets,
                [targetId]: placement._snippet
              })
            }

            if (_activePlacements.indexOf(placement.slug) === -1) {
              dispatch(updateActivePlacements([..._activePlacements, placement.slug]))
            }
          }
        } else {
          createPlacement(placement)
          setSnippets({
            ...snippets
          })
        }
      })
    })
  }, [placements, snippets])

  return (
    <>
      {Object.entries(snippets).map(([id, snippet]) => (
        <SnippetPortal id={id} data={snippet} key={id} />
      ))}
    </>
  )
}
