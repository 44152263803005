import React, { useEffect, useState } from 'react'
import ButtonModel from './models'
import styled from '@emotion/styled'
import ButtonStyleModel from '../ButtonStyle/models'
import { useCart } from '@shopify/hydrogen-react'
import { useAppSelector } from '../../redux'
import Loading from '../../components/Loading'
import { FiLoader } from 'react-icons/fi'
import { AiOutlineLoading } from 'react-icons/ai'

interface ContentProps {
  data?: ButtonModel
  containerProps?: string[]
  dataContext?: any
}

interface ButtonStyleWrapperProps {
  model?: ButtonModel
  buttonStyle?: ButtonStyleModel
}

const ButtonStyleWrapper = styled.button<ButtonStyleWrapperProps>`
  display: 'inline-block';
  padding-top: ${(props) => props.buttonStyle?.paddingTop};
  padding-right: ${(props) => props.buttonStyle?.paddingRight};
  padding-bottom: ${(props) => props.buttonStyle?.paddingBottom};
  padding-left: ${(props) => props.buttonStyle?.paddingLeft};
  margin-top: ${(props) => props.buttonStyle?.marginTop};
  margin-right: ${(props) => props.buttonStyle?.marginRight};
  margin-bottom: ${(props) => props.buttonStyle?.marginBottom};
  margin-left: ${(props) => props.buttonStyle?.marginLeft};
  font-size: ${(props) => props.buttonStyle?.fontSize};
  background-color: ${(props) => props.buttonStyle?.backgroundColor};
  border-width: ${(props) => props.buttonStyle?.borderThickness};
  border-color: ${(props) => props.buttonStyle?.borderColor};
  border-radius: ${(props) => props.buttonStyle?.borderRadius};
  color: ${(props) => props.buttonStyle?.textColor};
  width: ${(props) =>
    props.buttonStyle?.widthType === 'auto' ? 'auto' : props.buttonStyle?.widthValue};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  &:not([disabled]):hover {
    background-color: ${(props) => props.buttonStyle?.backgroundColorHover};
    border-color: ${(props) => props.buttonStyle?.borderColorHover};
    color: ${(props) => props.buttonStyle?.textColorHover};
  }
`

export default function CheckoutButton({ data, containerProps, dataContext }: ContentProps) {
  const buttonStyle = data?.buttonStyle === 'custom' ? data?.customStyle : data?._buttonStyle
  const cart = useCart()
  const admin = useAppSelector((state) => state.app.isAdmin)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  const [requestedCheckout, setRequestedCheckout] = useState(false)

  useEffect(() => {
    if (requestedCheckout && cart.checkoutUrl && cart.status === 'idle') {
      setRequestedCheckout(false)
      window.location.href = cart.checkoutUrl
    }
    
  }, [requestedCheckout, cart.status, cart.checkoutUrl])

  if (admin && !enableHeadlessCommerce) {
    return <div>Headless Commerce not enabled.</div>
  }

  if (!enableHeadlessCommerce) {
    return <></>
  }

  if ((cart?.lines || []).length <= 0) return <></>

  return (
    <ButtonStyleWrapper model={data} buttonStyle={buttonStyle} {...containerProps} onClick={() => setRequestedCheckout(true)}>
      {requestedCheckout ? <AiOutlineLoading className='animate-spin' /> : data?.title}
    </ButtonStyleWrapper>
  )
}
