import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { RootState } from '..'

interface ProductState {
  products: { [id: string]: any }
}

const initialState: ProductState = {
  products: {}
}

let cancelToken: CancelTokenSource | null = null

interface GetProductParams {
  id: string
  idType: 'id' | 'sourceId'
}

/**
 * Handles loading a single product payload into redux
 */
export const getProduct = createAsyncThunk(
  'product/load',
  async ({ id, idType }: GetProductParams, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    const cachedProducts = state.product.products

    if (id && cachedProducts[id]) {
      return {
        id,
        product: cachedProducts[id]
      }
    }

    if (cancelToken) {
      // cancel previous requests
      cancelToken.cancel()
    }

    cancelToken = axios.CancelToken.source()

    try {
      const path = '/products/es/search/' + id
      const response = await axios.get(path, {
        params: {
          idType
        },
        cancelToken: cancelToken.token
      })

      cancelToken = null
      return {
        id,
        product: response.data
      }
    } catch (e) {
      const ex = e as AxiosError
      if (ex.message) {
        console.log(e)
      }
      rejectWithValue('Product not found')
    }
  }
)

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getProduct.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.id && action.payload.product) {
          state.products = {
            ...state.products,
            [action.payload.id]: action.payload.product
          }
        }
      }
    )
  }
})

export const {} = productSlice.actions
export default productSlice.reducer
