export class WishListLauncherAppData {
  displayType?: 'floating' | 'embedded' = 'floating'
  target?: string = ''
  floatingLocation?: 'bottomLeft' | 'bottomRight' = 'bottomLeft'
  icon?: 'heart' | 'star' | 'none' = 'heart'
  label?: string = ''
  showLabel?: boolean = false
  backgroundColor?: string = 'rgb(0, 0, 0, 1)'
  hoverBackgroundColor?: string = 'rgba(104, 104, 104, 1)'
  foregroundColor?: string = 'rgba(255, 255, 255, 1)'
  hoverforegroundColor?: string = 'rgba(255, 255, 255, 1)'
  borderColor?: string = ''
  borderRadius?: number = 0
  borderWidth?: number = 0
  paddingHorizontal?: number = 20
  paddingVertical?: number = 10
  showItemCount?: boolean = false
  shadow?: boolean = false
}

export class WishListNotificationAppData {
  displayType?: 'basic' | 'product' = 'basic'
  title?: string = 'was added to your list'
  removeTitle?: string = 'was removed from your list'
  titleUp?: string = 'was upvoted'
  titleDown?: string = 'was downvoted'
  titleResponse?: string = 'response added'
  linkTitle?: string = ''
  linkUrl?: string = '/pages/wishlist'
}

export class WishListAddButtonAppData {
  target?: 'collectionImage' | 'custom' = 'collectionImage'
  score?: number = 1
  customTarget?: string = ''
  showSocialProof?: boolean = true
  truncateLargeCounts?: boolean = true
  paddingHorizontal?: number = 20
  paddingVertical?: number = 10
  label?: string = ''
  labelDown?: string = ''
  icon?: 'like' | 'dislike' | 'like-filled' | 'dislike-filled' | 'heart' | 'heart-filled' | 'star' | 'star-filled' | 'none' = 'heart'
  backgroundColor?: string = 'rgb(0, 0, 0, 1)'
  hoverBackgroundColor?: string = 'rgba(104, 104, 104, 1)'
  foregroundColor?: string = 'rgba(255, 255, 255, 1)'
  hoverForegroundColor?: string = 'rgba(255, 255, 255, 1)'
  borderColor?: string = ''
  borderRadius?: number = 0
  borderWidth?: number = 0
  shadow?: boolean = false
  activeLabel?: string = ''
  activeLabelDown?: string = ''
  activeIcon?: 'like' | 'dislike' | 'like-filled' | 'dislike-filled' | 'heart' | 'heart-filled' | 'star' | 'star-filled' | 'none' = 'heart-filled'
  activeBackgroundColor?: string = 'rgb(0, 0, 0, 1)'
  activeHoverBackgroundColor?: string = 'rgb(0, 0, 0, 1)'
  activeForegroundColor?: string = 'rgb(0, 0, 0, 1)'
  activeHoverForegroundColor?: string = 'rgb(0, 0, 0, 1)'
  activeBorderColor?: string = ''
  activeBorderRadius?: number = 0
  activeBorderWidth?: number = 0
  activeShadow?: boolean = false
}

export class WishListManagerAppData {
  displayType?: 'page' | 'floating' = 'page'
  pagePath?: string = '/pages/wishlist'
  gridColumns?: number = 4
  gridColumnsMobile?: number = 2
  itemsPerPage?: number = 16
  shareEmailLogo?: string = ''
  shareEmailSubject?: string = ''
  allowSharingEmail?: boolean = true
  allowAnonymousUser?: boolean = true
  allowMultipleLists?: boolean = true
  defaultListName?: string = 'Wish List'
  tabBackgroundColor?: string = 'rgb(1, 1, 1, 1)'
  tabForegroundColor?: string = 'rgb(255, 255, 255, 1)'
  emptyMessage?: string = 'You have no items in your wishlist'
}