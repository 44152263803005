import React from 'react'
import styled from '@emotion/styled'
import CartLineItemsModel from './models'
import {
  CartLineProvider,
  CartLineQuantity,
  CartLineQuantityAdjustButton,
  Image,
  useCart,
  useMoney
} from '@shopify/hydrogen-react'
import { CartLine, ComponentizableCartLine } from '@shopify/hydrogen-react/storefront-api-types'
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi'
import DOMPurify from 'dompurify'
import { useAppSelector } from '../../redux'

interface Props {
  data: CartLineItemsModel
  containerProps: any
}

const Container = styled.div<{ data: CartLineItemsModel }>`
  padding: 0px 10px;

  .empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const LineContainer = styled.div`
  padding: 10px;

  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    h2 {
      margin-top: 0px;
      font-size: 1rem;
    }
  }

  .qty-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .qty-picker {
      display: flex;
      flex-direction: row;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #d0d0d0;
        width: 35px;
        height: 35px;
        cursor: pointer;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
        width: 35px;
      }
    }

    .btn-trash {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
`

export default function CartLineItems({ data, containerProps }: Props) {
  const { lines } = useCart()
  const admin = useAppSelector((state) => state.app.isAdmin)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  
  const cleanHtml = DOMPurify.sanitize(data.emptyMessage, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })
  
  if (admin && !enableHeadlessCommerce) {
    return <div>Headless Commerce not enabled.</div>
  }

  if (!enableHeadlessCommerce) {
    return <></>
  }

  return (
    <Container {...containerProps} data={data}>
      {lines && lines?.length > 0 ? (
        lines?.map((line) => (
          // @ts-ignore
          <LineItem line={line} key={line?.id} />
        ))
      ) : (
        <div className='empty-message' dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
      )}
    </Container>
  )
}

function LineItem({ line }: { line: CartLine | ComponentizableCartLine }) {
  const amount = useMoney(line.merchandise.price)

  return (
    <CartLineProvider line={line}>
      <LineContainer>
        <div>
          <div>
            <Image
              // @ts-ignore
              data={line.merchandise.image}
              sizes='(min-width: 45em) 50vw, 100vw'
              style={{ maxHeight: 'fit-content' }}
            />
          </div>
          <div className='content'>
            <div>
              <h2>{line.merchandise.product.title}</h2>
              <div className='qty-container'>
                <div className='qty-picker'>
                  <CartLineQuantityAdjustButton adjust='decrease'>
                    <FiMinus />
                  </CartLineQuantityAdjustButton>
                  <CartLineQuantity />
                  <CartLineQuantityAdjustButton adjust='increase'>
                    <FiPlus />
                  </CartLineQuantityAdjustButton>
                </div>
                <CartLineQuantityAdjustButton adjust='remove' className='btn-trash'>
                  <FiTrash2 />
                </CartLineQuantityAdjustButton>
              </div>
            </div>
            <div>{amount.withoutTrailingZeros}</div>
          </div>
        </div>
      </LineContainer>
    </CartLineProvider>
  )
}
