import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import CartLineItemsModel from './models'
import { useCart } from '@shopify/hydrogen-react'

import CartFreeShippingProgressModel from './models'

interface Props {
  data: CartFreeShippingProgressModel
  containerProps: any
}

const Container = styled.div<{ data: CartLineItemsModel; progress: number }>`
  margin: 10px;

  .shipping-progress-bar {
    height: 10px;
    border: 1px solid ${(props) => props.data.progressBarBorderColor};
    background-color: ${(props) => props.data.progressBarBackgroundColor};
    border-radius: 1000px;

    margin-top: 10px;
    margin-bottom: 10px;

    .progress {
      height: 10px;
      background-color: ${(props) => props.data.progressBarColor};
      width: ${(props) => `${props.progress}%`};
      border-radius: 1000px;
    }
  }
`

export default function CartFreeShippingProgress({ data, containerProps }: Props) {
  const freeShippingThreshold = data.freeShippingAmoount
  const cart = useCart()

  const subtotal = useMemo(() => {
    let subtotal = 0
    
    cart.lines?.forEach(line => {
      if (line?.merchandise?.product?.tags?.some(item => data.includeProductByTags.includes(item || ''))) {
        subtotal += +(line?.cost?.totalAmount?.amount || 0)
      }      
    })
    return subtotal
  }, [cart.lines])
  
  //const subtotal = +(cart.cost?.subtotalAmount?.amount || 0)
  const difference = freeShippingThreshold - subtotal

  let progress = (subtotal / freeShippingThreshold) * 100
  if (progress > 100) progress = 100
  if (progress < 0) progress = 0

  return (
    <Container {...containerProps} data={data} progress={progress}>
      {progress < 100 ? (
        <>{data.progressMessage.replace('{{ amount }}', `$${difference.toFixed(2)}`)}</>
      ) : (
        <>{data.progressCompleteMessage}</>
      )}
      <div>
        <div className='shipping-progress-bar'>
          <div className='progress'></div>
        </div>
      </div>
    </Container>
  )
}
